export const environment = {
  production: false,
  googleData: {
    client_id:
      '980592127086-e8516f7k4i82u1cfn2l9t8g0a375mm7d.apps.googleusercontent.com',
    redirect_uri: 'https://r.h3dqa.com/retailer/session/auth/google/sso',
    state: 'https://cms.h3dqa.com'
  },
  endPoints: {
    c3dDomain: 'c3dqa.com',
    cmsDomain: 'h3dqa.com',
    viewerDomain: 'vqa.hexa3d.io',
    creatorsViewerDomain: 'vqa.creators3d.com'
  },
  rootCssClass: 'qa',
  captchaSiteKey: '6Le3L48eAAAAAKWvJnJu-cTV9h9szQmwLIWzYTwd',
  environmentName: 'QA',
  favIcon: 'favicon.qa.ico',
  sentry: {
    isActivated: false,
    tracesSampleRate: 0.1,
    tracingOrigins: 'https://cms.h3dqa.com/'
  },
  csp: "default-src 'self' *.h3dqa.com; script-src 'self' *.h3dqa.com 'unsafe-eval' 'unsafe-inline' *.hexa3d.io *.google-analytics.com *.google.com *.gstatic.com *.googletagmanager.com cdn.paddle.com sandbox-cdn.paddle.com; style-src 'self' *.h3dqa.com 'unsafe-inline' *.googleapis.com *.hexa3d.io sandbox-cdn.paddle.com; base-uri 'self' *.h3dqa.com; connect-src 'self' blob: data: himg-cdn.com *.h3dqa.com *.hexa3d.io *.google-analytics.com *.azureedge.net *.gstatic.com *.googletagmanager.com *.sentry.io; font-src 'self' *.h3dqa.com *.hexa3d.io *.gstatic.com; frame-src 'self' *.h3dqa.com *.hexa3d.io *.google.com himg-cdn.com img-cdn.azureedge.net sandbox-buy.paddle.com; img-src blob: 'self' *.h3dqa.com https: data: *.hexa3d.io *.azureedge.net *.gstatic.com *.googletagmanager.com himg-cdn.com; manifest-src 'self' *.h3dqa.com; media-src 'self' *.h3dqa.com;  worker-src 'self' blob: *.hexa3d.io;",
  crateRID: 293,
  pcRID: 310,
  paddle: {
    environment: 'sandbox',
    token: 'test_8fae2c79c96206e154ed671c5c1',
  },
  allowRetailersExternalProviderWithoutIA: [240]
};
