import { Component, OnInit, Input, Output, ElementRef, EventEmitter, OnChanges, SimpleChanges, OnDestroy, ViewChild } from '@angular/core';
import { AssetAdjustmentsService, AdjustmentsSourceChanges, ResourceDimensions, AssetAdjustmentSector, KeyValuePair, EnumsService, RaycasterOptions, RaycasterType, BindSphereLightOptions, GltfValidationResponse, AssetAdjustmentType, AssetAdjustments, ConsoleMessageType, ApplicationProperties,ThreeMaterial, AssetAdjustmentPosition, MapAdjustmentOption, LightSphereArrow, ImagesFileTypes, AnnotationType, MeshData, HierarchyTree, CameraControlsState, RenderingEngine, AmazonPresets, MAPS_DICTIONARY, Annotation, RenderTechnology } from 'asset-adjustments';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { Subscription } from 'rxjs';
import { BroadcasterService } from 'ng-broadcaster';
import { FileObject } from 'src/app/shared/file';
import { ProductResource } from 'src/app/product/product';
import { RolesHelperService } from 'src/app/auth/roles-helper.service';
import { AuthService } from 'src/app/auth/auth.service';
import { AssetAdjustmentsHelperService } from 'src/app/shared/asset-adjustments-helper.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ResumableSubject, ResumableState, ResumableCDN } from 'src/app/shared/resumable';
import { BroadcasterNotification, BroadcasterNotificationType } from 'src/app/communication/broadcaster-notifications';
import { RestService } from 'src/app/communication/rest.service'
import { ResumableUploadService } from 'src/app/shared/resumable-upload.service';
import { ArtistsJobsResourcesAlignment } from 'src/app/product/resource';
import { MatSelectChange } from '@angular/material/select';
import { RetailersMeshConvention } from 'src/app/retailer/retailer';
import { BodyService } from 'src/app/shared/body.service';

@Component({
    selector: 'app-asset-adjustments',
    templateUrl: './asset-adjustments.component.html',
    styleUrls: ['./asset-adjustments.component.scss'],
    standalone: false
})
export class AssetAdjustmentsComponent implements OnInit, OnChanges, OnDestroy {
  static MAPS_DICTIONARY = MAPS_DICTIONARY;
  @Input('iframe-model') iframeModel: ElementRef;
  @Input('created-at') createdAt: string;
  @Input() resource: ProductResource;
  @Input('tutorial-enabled') tutorialEnabled: boolean;
  @Input() hide: boolean;
  // @Input('adjustments-data') adjustmentsData: AdjustmentsData;
  @Input('jump-to-tab') jumpToTab: AssetAdjustmentSector;
  @ViewChild('mainAccordion') mainAccordion: MatAccordion;
  @ViewChild('scenePanel') scenePanel: MatExpansionPanel;
  @ViewChild('meshsPanel') meshsPanel: MatExpansionPanel;
  @ViewChild('alignmentsPanel') alignmentsPanel: MatExpansionPanel;
  @ViewChild('materialsPanel') materialsPanel: MatExpansionPanel;
  @Input() embed: boolean;
  @Input('export-progress') exportProgress: number;
  @Input('mesh-names') meshNames: Array<RetailersMeshConvention>;
  @Output('change-src') changeSrc: EventEmitter<AdjustmentsSourceChanges>;
  @Output('change-json-params') changeJsonParams: EventEmitter<string>;
  @Output('on-dimensions') changeBB: EventEmitter<ResourceDimensions>;
  @Output('on-fully-loaded') onFullyLoaded: EventEmitter<null>;
  @Output('on-alignment') onAlignment: EventEmitter<ArtistsJobsResourcesAlignment>;
  public SLIDER: AssetAdjustmentType;
  public SELECT: AssetAdjustmentType;
  public TOGGLE: AssetAdjustmentType;
  public CUSTOM: AssetAdjustmentType;
  public JOYSTICK: AssetAdjustmentType;
  public VECTOR3: AssetAdjustmentType;
  public IMAGE_MENU: AssetAdjustmentType;
  public SCENE: AssetAdjustmentSector;
  public LIGHTING: AssetAdjustmentSector;
  public SHADOW: AssetAdjustmentSector;
  public MATERIALS: AssetAdjustmentSector;
  public MESHES: AssetAdjustmentSector;
  public ANNOTATIONS: AssetAdjustmentSector;
  public OPTIMIZATIONS: AssetAdjustmentSector;
  public MESH_ANIMATIONS: AssetAdjustmentSector;
  public HIERARCHY_TREE: AssetAdjustmentSector;
  public POSTPROCESSING: AssetAdjustmentSector;
  public ALIGNMENTS: AssetAdjustmentSector;
  public BEFORE: AssetAdjustmentPosition;
  public CENTER: AssetAdjustmentPosition;
  public AFTER: AssetAdjustmentPosition;
  public TOP: number;
  public BOTTOM: number;
  public isSU: boolean;
  public mapsDictionary: { [id: string]: MapAdjustmentOption };
  public imageManipulation: boolean;
  public textureWrapTypes: Array<KeyValuePair>;
  public renderSides: Array<KeyValuePair>;
  public fileUploadsProgress: Array<number>;
  public currentLightSphereArrow: LightSphereArrow;
  public currentLight: number;
  public allLightTypes: Array<KeyValuePair>;
  public allThreeJsVersions: Array<KeyValuePair>;
  public allBabylonJsVersions: Array<string>;
  public RenderTechnology = RenderTechnology;
  public gltfValidation: GltfValidationResponse;
  public newMaterialName: string;
  public lights: Array<AssetAdjustments>;
  public hdriImages: { [id: string]: KeyValuePair };
  public pivotChangeValueX: AssetAdjustmentPosition;
  public pivotChangeValueY: AssetAdjustmentPosition;
  public pivotChangeValueZ: AssetAdjustmentPosition;
  public inputChangeValueX;
  public inputChangeValueY;
  public inputChangeValueZ;
  public isDistanceFree: boolean;
  public RenderingEngine = RenderingEngine;
  public AMAZON_DIRECTIONAL = AmazonPresets.DIRECTIONAL;
  public AMAZON_AMBIENT = AmazonPresets.AMBIENT;
  public AMAZON_NEUTRAL = AmazonPresets.NEUTRAL;
  public AMAZON_CHROMATIC = AmazonPresets.CHROMATIC;
  public mergeVerticesFactor: number;
  public materializeMeshes: Array<{ key: string, value: any }>;
  private subs: Array<Subscription>;
  private afterLightsUpdate: Array<Function>;
  private onSetMaterials: Array<Function>;
  private meshLastName: string;

  constructor(
    public assetAdjustmentsService: AssetAdjustmentsService,
    public rolesHelper: RolesHelperService,
    public auth: AuthService,
    // private tutorialService: TutorialService,
    public assetAdjustmentsHelperService: AssetAdjustmentsHelperService,
    private utils: UtilsService,
    private body: BodyService,
    private broadcaster: BroadcasterService,
    private enums: EnumsService,
    private resumableUpload: ResumableUploadService,
    private rest: RestService,
    private dialog: MatDialog,
    // private css: ColorSamplingService
  ) {
    this.subs = [];
    this.lights = [];
    this.fileUploadsProgress = [];
    this.afterLightsUpdate = [];
    this.onSetMaterials = [];
    this.currentLightSphereArrow = {} as LightSphereArrow;
    this.mapsDictionary = this.utils.deepCopyByValue(AssetAdjustmentsComponent.MAPS_DICTIONARY);
    this.isSU = rolesHelper.isSuperUserOrObserverLoggedIn();
    this.assetAdjustmentsService.showOutline = true;
    this.assetAdjustmentsService.currentTabIndex = 0;
    this.changeSrc = new EventEmitter<AdjustmentsSourceChanges>();
    this.changeBB = new EventEmitter<ResourceDimensions>();
    this.onFullyLoaded = new EventEmitter<null>();
    this.onAlignment = new EventEmitter<ArtistsJobsResourcesAlignment>();
    this.assetAdjustmentsService.imagesType = ImagesFileTypes.ANY;

    this.SLIDER = AssetAdjustmentType.SLIDER;
    this.SELECT = AssetAdjustmentType.SELECT;
    this.TOGGLE = AssetAdjustmentType.TOGGLE;
    this.CUSTOM = AssetAdjustmentType.CUSTOM;
    this.JOYSTICK = AssetAdjustmentType.JOYSTICK;
    this.VECTOR3 = AssetAdjustmentType.VECTOR3;
    this.IMAGE_MENU = AssetAdjustmentType.IMAGE_MENU;

    this.SCENE = AssetAdjustmentSector.SCENE;
    this.LIGHTING = AssetAdjustmentSector.LIGHTING;
    this.SHADOW = AssetAdjustmentSector.SHADOW;
    this.MATERIALS = AssetAdjustmentSector.MATERIALS;
    this.MESHES = AssetAdjustmentSector.MESHES;
    this.ANNOTATIONS = AssetAdjustmentSector.ANNOTATIONS;
    this.OPTIMIZATIONS = AssetAdjustmentSector.OPTIMIZATIONS;
    this.MESH_ANIMATIONS = AssetAdjustmentSector.MESH_ANIMATIONS;
    this.HIERARCHY_TREE = AssetAdjustmentSector.HIERARCHY_TREE;
    this.POSTPROCESSING = AssetAdjustmentSector.POSTPROCESSING;
    this.ALIGNMENTS = AssetAdjustmentSector.ALIGNMENTS;

    this.BEFORE = AssetAdjustmentPosition.BEFORE;
    this.CENTER = AssetAdjustmentPosition.CENTER;
    this.AFTER = AssetAdjustmentPosition.AFTER;
    this.TOP = AssetAdjustmentPosition.TOP;
    this.BOTTOM = AssetAdjustmentPosition.BOTTOM;

    this.mergeVerticesFactor = 0.1;

    this.assetAdjustmentsService.firstInit();
    this.textureWrapTypes = this.enums.getTextureWrapTypes();
    this.renderSides = this.enums.getSidesRender(false);
    this.allLightTypes = this.enums.getLightsTypes();
    this.allThreeJsVersions = this.enums.getThreeJsVersions();
    this.allBabylonJsVersions = this.enums.getBabylonJsVersions();

    this.hdriImages = {};
    const hdrs = this.enums.getHDRs();
    const hdrsFull = this.enums.getHDRIFull();
    const hdrsPrev = this.enums.getHDRIPrev();
    hdrs.forEach(h => this.hdriImages[h.value] = { key: hdrsFull[h.key], value: hdrsPrev[h.key] });
  }

  ngOnInit() {
    this.initParams();
    this.subs.push(this.assetAdjustmentsService.broadcaster.subscribe(this.onAdjustmentMessage.bind(this)));
    // this.css.adjustmentsData = this.adjustmentsData;
  }

  private initParams(): void {
    this.assetAdjustmentsHelperService.initParams();
  }

  public showGltfErrors(): void {
    alert(this.gltfValidation);
    // this.dialog.open(GltfValidationOutputDialogComponent, {
    //   data: this.gltfValidation
    // });
  }

  private onAdjustmentMessage(message: any): void {
    switch (message.key) {
      case 'notifyUser': {
        this.broadcaster.broadcast('notifyUser', message.data);
        break;
      }
      // case 'viewerFullyLoaded': {
      //   break;
      // }
      case 'onUVsOverlap': {//'viewerFullyLoaded': {
        this.assetAdjustmentsHelperService.isViewerFullyLoaded = true;
        break;
      }
      case 'viewerloaded': {
        this.onViewerLoaded();
        break;
      }
      case 'onConsole': {
        if (message.message && message.data?.args && message.data.args.length) {
          const data: BroadcasterNotification = {
            text: message.message.data.args[0],
            type: message.data.type === ConsoleMessageType.ERROR ? BroadcasterNotificationType.Error : BroadcasterNotificationType.Info,
            action: 'OK'
          }
          this.broadcaster.broadcast('notifyUser', data);
        } else {
          console.warn(message);
        }
        break;
      }
      case 'GltfValidation': {
        // this.gltfValidation = message.data;
        // if (this.gltfValidation.issues.numErrors) {
        //   let data: Notification = {
        //     text: `glTF has ${this.gltfValidation.issues.numErrors} errors!`,
        //     type: NotificationType.Error,
        //     action: 'OK'
        //   }
        //   this.broadcaster.broadcast('notifyUser', data);
        // }
        break;
      }
      case 'onModelLoadError': {
        if (message.data) {
          const data: BroadcasterNotification = {
            text: message.data,// || 'Failure loading model',
            type: BroadcasterNotificationType.Error,
            action: 'OK'
          }
          this.broadcaster.broadcast('notifyUser', data);
        }
        break;
      }
      case 'onLightsSummary': {
        this.lights = this.assetAdjustmentsService.parameters.filter(p => p.isLight);
        this.afterLightsUpdate.forEach(f => f());
        this.afterLightsUpdate = [];
        break;
      }
      case 'setMaterials': {
        this.onSetMaterials.forEach(f => f());
        this.onSetMaterials = [];
        break;
      }
    }
  }

  // when a viewer is loaded but doesn't necessarily have a model
  private onViewerLoaded(): void {
    this.assetAdjustmentsHelperService.isViewerFullyLoaded = true;
    this.onFullyLoaded.next(null);
    delete this.gltfValidation;
  }

  // sendFileToViewer() {
  //   // if (this.zipFile && !this.zipSent && this.assetAdjustmentsService.isViewerLoaded) {
  //   if (this.filesToViewer && !this.filesSent) {
  //     this.filesSent = true;
  //     setTimeout(() => {
  //       this.assetAdjustmentsService.postToChild('displayFiles', {
  //         'files': this.filesToViewer
  //       }, new PostToChildOptions(false));
  //     }, 1000);
  //   }
  // }

  private resetPivot(): void {
    delete this.pivotChangeValueX;
    delete this.pivotChangeValueY;
    delete this.pivotChangeValueZ;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.resource && changes.resource.currentValue && changes.resource.currentValue.viewer_url) {
      this.iframeModel.nativeElement.src = changes.resource.currentValue.viewer_url;
    }
    this.mapService();
    for (const propName in changes) {
      if (Reflect.has(changes, propName)) {
        if (propName === 'createdAt' && changes['createdAt'].currentValue !== changes['createdAt'].previousValue && changes['createdAt'].previousValue) {
          this.assetAdjustmentsService.frameEventsAttached = false;
          this.assetAdjustmentsService.init(changes['iframeModel'] ? changes['iframeModel'].previousValue.nativeElement.src : this.assetAdjustmentsService.lastFrameSrc);
          delete this.gltfValidation;
          this.resetPivot();
        }
        if (propName === 'iframeModel' && !changes['iframeModel'].previousValue) {
          this.initParams();
          this.broadcaster.broadcast('OnAssetAdjustmentsComponentInit');
          this.resetPivot();
        }
      }
    }
    if (changes.jumpToTab && !changes.jumpToTab.firstChange) {
      let p: MatExpansionPanel;
      switch (this.jumpToTab) {
        case AssetAdjustmentSector.ALIGNMENTS: {
          p = this.alignmentsPanel;
          break;
        }
        case AssetAdjustmentSector.SCENE: {
          p = this.scenePanel;
          break;
        }
        case AssetAdjustmentSector.MESHES: {
          p = this.meshsPanel;
          break;
        }
      }
      if (p) {
        p.open();
      }
    }
  }

  public async toggleMeshByName(name: string): Promise<void> {
    if (this.assetAdjustmentsService.selectedMesh && this.assetAdjustmentsService.selectedMesh.name === name) {
      await this.assetAdjustmentsService.selecteMeshByName(null);
    } else {
      await this.assetAdjustmentsService.selecteMeshByName(name);
    }
  }

  private mapService(): void {
    this.assetAdjustmentsService.iframeModel = this.iframeModel;
    this.assetAdjustmentsService.embed = this.embed;
    if (this.resource) {
      this.assetAdjustmentsService.viewerType = this.resource.viewer_type;
      this.assetAdjustmentsService.resourceType = this.resource.resource_type;
      this.assetAdjustmentsService.jsonParams = this.utils.getResourceJsonParams(this.resource.json_params, this.resource.viewer_resource_type);
    } else {
      delete this.assetAdjustmentsService.viewerType;
      delete this.assetAdjustmentsService.resourceType;
    }
    this.assetAdjustmentsService.changeSrc = this.changeSrc;
    this.assetAdjustmentsService.createdAt = this.createdAt;
    this.assetAdjustmentsService.changeBB = this.changeBB;
  }

  public doesMaterialHasMap(material: ThreeMaterial, mapName: string): boolean {
    return !!material[mapName];
  }

  // onTextureRemove(type: string) {
  //   let options = {
  //     'map': type,
  //     'remove': true
  //   } as any;
  //   this.assetAdjustmentsService.refreshMaterials = true;
  //   this.assetAdjustmentsService.refreshTextures = true;
  //   this.assetAdjustmentsService.postToChild('mapTextureToMaterial', {
  //     'texture': null, 'material': this.assetAdjustmentsService.materials[this.assetAdjustmentsService.currentMaterialIndex].name, "options": options
  //   });
  //   this.assetAdjustmentsService.counter++;
  //   this.assetAdjustmentsService.mappingChanged = true;
  // }

  // onTextureRestore(type: string) {
  //   this.assetAdjustmentsService.refreshMaterials = true;
  //   this.assetAdjustmentsService.refreshTextures = true;
  //   this.assetAdjustmentsService.postToChild('restoreOriginalMapImage', {
  //     'name': this.assetAdjustmentsService.materials[this.assetAdjustmentsService.currentMaterialIndex][type].name,
  //     'type': type
  //   });
  //   this.assetAdjustmentsService.counter++;
  //   this.assetAdjustmentsService.mappingChanged = true;
  // }

  public onMaterialProps(data: any): void {
    this.assetAdjustmentsService.setMaterialProps(data[0], data[1], data[2], data[3]);
  }

  public onTextureProps(data: any): void {
    this.assetAdjustmentsService.setTextureProps(data[0], data[1], data[2], data[3]);
  }

  public exportImagesType(): void {
    this.assetAdjustmentsService.export({ imagesType: this.assetAdjustmentsService.imagesType });
  }

  public toggleStats(state: boolean): void {
    this.assetAdjustmentsService.setProps({ stats: state } as ApplicationProperties);
  }

  // onTextureChange(texture: TexturedMaterial) {
  //   if (texture && this.assetAdjustmentsService.texturedMaterials[this.assetAdjustmentsService.materials[this.assetAdjustmentsService.currentMaterialIndex].name] &&
  //     this.assetAdjustmentsService.texturedMaterials[this.assetAdjustmentsService.materials[this.assetAdjustmentsService.currentMaterialIndex].name][texture.type] &&
  //     this.assetAdjustmentsService.texturedMaterials[this.assetAdjustmentsService.materials[this.assetAdjustmentsService.currentMaterialIndex].name][texture.type].src &&
  //     !this.assetAdjustmentsService.texturesSrcs.find(s => s == this.assetAdjustmentsService.texturedMaterials[this.assetAdjustmentsService.materials[this.assetAdjustmentsService.currentMaterialIndex].name][texture.type].src)) {
  //     this.assetAdjustmentsService.texturesSrcs.push(this.assetAdjustmentsService.texturedMaterials[this.assetAdjustmentsService.materials[this.assetAdjustmentsService.currentMaterialIndex].name][texture.type].src);
  //   }
  //   let options = {
  //     'map': texture ? texture.relatedType ? texture.relatedType : texture.type : null,
  //     'intensity': typeof texture.channel === 'number' ? texture.intensity : (texture && this.assetAdjustmentsService.texturesWithImages[texture.name]) ? this.assetAdjustmentsService.texturesWithImages[texture.name].intensity : null,
  //     'textureSrc': (texture && this.assetAdjustmentsService.texturesWithImages[texture.name]) ? this.assetAdjustmentsService.texturesWithImages[texture.name].src : null,
  //     'textureName': texture ? texture.name : null,
  //     'srcChange': texture ? texture.srcChange : false,
  //     'channel': texture.channel,
  //     // 'manipulation': texture.manipulation,
  //     'aoManipulation': texture.aoManipulation,
  //     'roughnessManipulation': texture.roughnessManipulation,
  //     'metalnessManipulation': texture.metalnessManipulation,
  //     'mapManipulation': texture.mapManipulation,
  //     'normalManipulation': texture.normalManipulation,
  //     'color': texture['color'],
  //     'videoSrc': texture.videoSrc
  //   } as any;
  //   if (texture && typeof texture.flipY === 'boolean')
  //     options.flipY = texture.flipY;
  //   this.assetAdjustmentsService.postToChild('mapTextureToMaterial', {
  //     'texture': texture ? texture.src : null, 'material': this.assetAdjustmentsService.materials[this.assetAdjustmentsService.currentMaterialIndex].name, "options": options
  //   });
  //   this.assetAdjustmentsService.mappingChanged = true;
  //   if (texture.videoSrc)
  //     this.assetAdjustmentsService.materialManipulations[this.assetAdjustmentsService.materials[this.assetAdjustmentsService.currentMaterialIndex].name].video = {
  //       mapType: options.map,
  //       src: texture.videoSrc
  //     };
  //   // this.assetAdjustmentsService.counter++;
  // }

  public getImageUrlByName(name: string): string {
    let res = null;
    if (this.assetAdjustmentsService.materials && this.assetAdjustmentsService.materials.length) {
      this.assetAdjustmentsService.materials.forEach(material => {
        res = material.images.find(i => i.name === name);
        if (res) {
          return res;
        }
      })
    }
    return res;
  }

  public onMaterialChange(): void {
    this.assetAdjustmentsService.onMaterialChange();
    this.assetAdjustmentsService.refreshTexturedMaterialsMap();
    this.newMaterialName = this.assetAdjustmentsService.materials[this.assetAdjustmentsService.currentMaterialIndex].name;
  }

  public onMaterialTypeChange(): void {
    this.assetAdjustmentsService.onMaterialTypeChange();
    this.assetAdjustmentsService.refreshTexturedMaterialsMap();
  }

  public onMaterialPropChange(mappingChanged: boolean): void {
    // setTimeout(() => {
      this.assetAdjustmentsService.onMaterialPropChange(mappingChanged);
    // }, 500);
  }

  private broadcastShowOutline(): void {
    this.assetAdjustmentsService.broadcastShowOutline();
  }

  public toggleOutline(): void {
    if (this.assetAdjustmentsService.materials && this.assetAdjustmentsService.materials[this.assetAdjustmentsService.currentMaterialIndex]) {
      if (this.assetAdjustmentsService.showOutline && this.assetAdjustmentsService.currentTabIndex === AssetAdjustmentSector.MATERIALS) {
        this.broadcastShowOutline();
      } else {
        this.assetAdjustmentsService.postToChild('removeMaterialOutline');
      }
    }
  }

  public onFilesChange(fileList: Array<FileObject>): void {
    let totalVideoUploads = 0;
    let counter = 0;
    const uploadVideo = (index: number, file: FileObject) => {
      const sub = this.resumableUpload.sourceFiles(file.file);
      sub.subscribe((res: ResumableSubject) => {
        switch (res.state) {
          case ResumableState.FILE_PROGRESS: {
            this.fileUploadsProgress[index] = res.object.progress;
            break;
          }
          case ResumableState.ERROR: {
            console.warn(res);
            console.warn('Something went wrong during upload of a specific file, ERROR . . .');
            const data: BroadcasterNotification = {
              text: 'Connection was interrupted, please try again.',
              type: BroadcasterNotificationType.Error,
              action: 'OK'
            }
            this.broadcaster.broadcast('notifyUser', data);
            break;
          }
          case ResumableState.COMPLETE: {
            const payload = new ResumableCDN();
            payload.compress = true;
            payload.uploaded_file_name = `${file.name}.${file.suffix}`;
            payload.uploaded_file_url = res.object.message;
            this.rest.afterCdn('post', payload).subscribe(
              data => {
                this.assetAdjustmentsService.videoTexturesSrcs.push(data['url']);
                if (--totalVideoUploads === 0) {
                  afterFile();
                  this.fileUploadsProgress = [];
                }
              },
              err => this.utils.httpErrorResponseHandler(err, 'video upload failed')
            );
          }
        }
      });
    };
    const afterFile = () => {
      if (!this.assetAdjustmentsService.textures) {
        this.assetAdjustmentsService.textures = [];
        this.assetAdjustmentsService.onMaterialsTextures();
      }
      this.assetAdjustmentsService.counter++;
    };
    if (fileList.length > 0) {
      fileList.forEach(file => {
        if (file.file.type && file.file.type.indexOf('video') > -1) {
          totalVideoUploads++;
          this.fileUploadsProgress.push(0);
          new uploadVideo(counter++, file);
          // this.assetAdjustmentsService.videoTexturesSrcs.push(file.base64);
        } else {
          this.assetAdjustmentsService.texturesSrcs.push(file.base64);
        }
      });
      afterFile();
    }
  }

  public hasReflectivity(): boolean {
    const type = this.assetAdjustmentsService.materialManipulations[this.assetAdjustmentsService.materials[this.assetAdjustmentsService.currentMaterialIndex].name].type;
    return type === 'MeshPhysicalMaterial' || type === 'MeshPhongMaterial' || type === 'MeshBasicMaterial' || type === 'MeshLambertMaterial' || type === 'MeshLambertMaterial';
  }

  public toggleMaterialExpandedState(matName: string, mapKey: string, state: boolean): void {
    this.assetAdjustmentsService.materialAccordion[matName + '_' + mapKey] = state;
  }

  public tabIndexChange(index: AssetAdjustmentSector): void {
    this.assetAdjustmentsService.currentTabIndex = index;
    if ((this.assetAdjustmentsService.currentTabIndex === AssetAdjustmentSector.MATERIALS || this.assetAdjustmentsService.currentTabIndex == AssetAdjustmentSector.MESHES) && !this.assetAdjustmentsService.loading && (!this.assetAdjustmentsService.materials || this.assetAdjustmentsService.textures)) {
      this.assetAdjustmentsService.postToChild('broadcastMaterials');
      this.assetAdjustmentsService.postToChild('broadcastTextures');
    }
    if (this.assetAdjustmentsService.currentTabIndex === AssetAdjustmentSector.MESHES && !this.assetAdjustmentsService.meshesData) {
      this.assetAdjustmentsService.getMeshesData();
    }
    if (this.assetAdjustmentsService.currentTabIndex === AssetAdjustmentSector.HIERARCHY_TREE) {
      this.assetAdjustmentsService.postToChild('broadcastSceneHierarchy');
    }
    const rayCasterOptions = {
      type: RaycasterType.HIGHLIGHT
    } as RaycasterOptions;
    if (this.assetAdjustmentsService.currentTabIndex === AssetAdjustmentSector.MESHES) {
      this.assetAdjustmentsService.setRaycasterState(true, rayCasterOptions);
    } else {
      this.assetAdjustmentsService.setRaycasterState(false, rayCasterOptions);
      this.assetAdjustmentsService.selecteMeshByName(null);
    }
    rayCasterOptions.type = RaycasterType.SPHERE_CURSOR;
    if (this.assetAdjustmentsService.currentTabIndex === AssetAdjustmentSector.LIGHTING ||
      this.assetAdjustmentsService.currentTabIndex === AssetAdjustmentSector.SHADOW) {
      this.currentLightSphereArrowChange();
    } else {
      this.assetAdjustmentsService.bindSphereToLight(false);
      this.assetAdjustmentsService.setRaycasterState(false, rayCasterOptions);
    }
    this.assetAdjustmentsService.recoverMuteLights();
    this.toggleOutline();
  }

  public addLight(type: string): void {
    this.assetAdjustmentsService.addLight(type);
    const max = Math.max.apply(null, this.lights.filter(l => l.options && l.options.type == type).map(l => l.index));
    this.afterLightsUpdate.push(() => {
      let run = false;
      for (let i = 0; i < this.lights.length; i++) {
        if (this.lights[i].index > max && this.lights[i].options.type == type) {
          this.changeLight(i);
          run = true;
          break;
        }
      }
      return run;
    });
  }


  public duplicateLight(): void {
    const light = this.lights[this.currentLight];
    if(light) {
      this.assetAdjustmentsService.duplicateLight(light.options.type, light.index);
    }
  }

  public soloChanged(): void {
    const light = this.lights[this.currentLight];
    if (this.assetAdjustmentsService.soloLights[light.options.type][light.index]) {
      // this.assetAdjustmentsService.muteLights.apply(this.assetAdjustmentsService, [this.light.options.type, this.light.index]);
      this.assetAdjustmentsService.muteLights(light.options.type, light.index);
    } else {
      // this.assetAdjustmentsService.recoverMuteLights.apply(this.assetAdjustmentsService);
      this.assetAdjustmentsService.recoverMuteLights();
    }
  }

  public deleteLight(): void {
    const current = this.lights[this.currentLight];
    this.assetAdjustmentsService.deleteLight(current.name + 'Light', current.index);
    delete this.currentLight;
    this.assetAdjustmentsService.bindSphereToLight(false);
    const rayCasterOptions = {
      type: RaycasterType.HIGHLIGHT
    } as RaycasterOptions;
    this.assetAdjustmentsService.setRaycasterState(false, rayCasterOptions);
  }

  public removeAllLights(): void {
    if (!confirm('Are you sure you want to delete all lights?')) {
      return;
    }
    this.assetAdjustmentsService.removeAllLights();
  }

  private currentLightSphereArrowChange(): void {
    if (this.hasCurrentLight()) {
      const bslOptions = {
        type: this.lights[this.currentLight].name + 'Light',
        index: this.lights[this.currentLight].index
      } as BindSphereLightOptions;
      this.assetAdjustmentsService.bindSphereToLight(true, bslOptions);
      const rayCasterOptions = {
        type: RaycasterType.SPHERE_CURSOR
      } as RaycasterOptions;
      this.assetAdjustmentsService.setRaycasterState(true, rayCasterOptions);
    }
  }

  private hasCurrentLight(): boolean {
    return typeof this.currentLight === 'number' && !!this.lights[this.currentLight];
  }

  public changeLight(index: number): void {
    this.currentLight = index;
    this.currentLightSphereArrowChange();
    this.assetAdjustmentsService.recoverMuteLights();
  }

  public cameraPositionChange(evt: MatButtonToggleChange): void {
    this.assetAdjustmentsService.setCameraPositionByPreset(evt.value);
  }

  public pivotChange(axis: string, evt: MatButtonToggleChange): void {
    if (!this.assetAdjustmentsService.mainMesh || !this.assetAdjustmentsService.boundingBox) {
      return;
    }
    const param = this.assetAdjustmentsService.modelPivot;
    param.value[axis] = evt.value;
    this.assetAdjustmentsService.fixPosition();

    if (axis === 'x') {
      this.inputChangeValueX = '';
    } else if (axis === 'y') {
      this.inputChangeValueY = '';
    } else if (axis === 'z') {
      this.inputChangeValueZ = '';
    }
  }

  public validateNumber(e: any): void {
    const input = e.target.value + String.fromCharCode(e.charCode);
    const reg = /^\d+\.?\d{0,2}?$/; // validate input 2 digit after dot

    if (!reg.test(input)) {
      e.preventDefault();
    }
  }

  public setPosition(axis: string, input): void {

    if (axis === 'x') {
      delete this.pivotChangeValueX;
    } else if (axis === 'y') {
      delete this.pivotChangeValueY;
    } else if (axis === 'z') {
      delete this.pivotChangeValueZ;
    }

    if (!this.assetAdjustmentsService.mainMesh || !this.assetAdjustmentsService.boundingBox) {
      return;
    }

    const param = this.assetAdjustmentsService.modelPivot;

    if (!isNaN(input)) {
      param.value[axis] = input;
      const vector = {
        x: param.value['x'] == null ? 0 : 1 * param.value['x'],
        y: param.value['y'] == null ? 0 : 1 * param.value['y'],
        z: param.value['z'] == null ? 0 : 1 * param.value['z'],
      }

      this.assetAdjustmentsService.setPositionByVector(vector);
    }

  }

  public applyAlignmentPosition(e: CameraControlsState): void {
    this.assetAdjustmentsService.setControlsPosition(e);
  }

  public rotationChange(): void {
    if (!this.assetAdjustmentsService.mainMesh || !this.assetAdjustmentsService.boundingBox) {
      return;
    }
    this.assetAdjustmentsService.fixRotation();
  }

  public resetRotation(): void {
    if (!this.assetAdjustmentsService.mainMesh || !this.assetAdjustmentsService.boundingBox) {
      return;
    }
    this.assetAdjustmentsService.modelRotation.value = { x: 0, y: 0, z: 0 };
    this.assetAdjustmentsService.modelRotation.degree = { x: 0, y: 0, z: 0 };
    this.assetAdjustmentsService.fixRotation();
  }

  public imageManipulationChange(): void {
    this.assetAdjustmentsService.counter++
  }

  public onNodeSelect(node: HierarchyTree): void {
    if (node) {
      let type = node.type;
      if (type.indexOf('Material') > -1) {
        type = 'Material';
      } else if (node.parentType.indexOf('Material') > -1) {
        type = 'Texture';
      }

      switch (type) {
        case 'Mesh': {
          this.highlightMesh(node.name);
          break;
        }
        case 'Texture': {
          this.highlightTexture(node.parentName, node.type);
          break;
        }
        case 'Material': {
          this.highlightMaterial(node.name);
          break;
        }
      }
    }
  }

  private highlightMaterial(materialName: string): void {
    this.materialsPanel.open();
    setTimeout(() => {
      for (let i = 0; i < this.assetAdjustmentsService.materials.length; i++) {
        if (this.assetAdjustmentsService.materials[i].name === materialName) {
          this.assetAdjustmentsService.currentMaterialIndex = i;
        }
      }
      this.body.scrollToElement(new ElementRef(document.getElementById('textures')));
    }, 500);
  }

  navToMeshes() {
    this.meshsPanel.open();
    setTimeout(() => {
        this.body.scrollToElement(new ElementRef(document.getElementById('meshs')));
    }, 500);
  }

  private highlightTexture(materialName: string, textureType: string): void {
    this.onSetMaterials.push(() => {
      for (let i = 0; i < this.assetAdjustmentsService.materials.length; i++) {
        if (this.assetAdjustmentsService.materials[i].name === materialName) {
          this.assetAdjustmentsService.currentMaterialIndex = i;
          this.onMaterialChange();
          break;
        }
      }
      textureType = textureType === 'diffuse' ? 'map' : textureType + 'Map';
      setTimeout(() => {
        const e = document.querySelector(`#textures .${textureType}`) as HTMLElement;
        if (e) {
          this.body.scrollToElement(new ElementRef(e), 'center');
          e.parentElement.parentElement.click();
        }
      }, 500);
    });
    this.materialsPanel.open();
  }

  private highlightMesh(meshName: string): void {
    this.meshsPanel.open();
    setTimeout(() => {
      this.toggleMeshByName(meshName);
      this.body.scrollToElement(new ElementRef(document.getElementById('meshs')));
    }, 500);
  }

  public deleteMesh(selectedMesh: MeshData): void {
    if (!confirm(`Are you sure you want to delete ${selectedMesh.name}?`)) {
      return;
    }
    this.assetAdjustmentsService.deleteMesh(selectedMesh);
  }

  public onSelectMenuClick(param: AssetAdjustments, value: any): void {
    param.value = value;
    this.assetAdjustmentsService.changeValue(param);
  }

  public async saveAll(): Promise<void> {
    if (this.assetAdjustmentsService.selectedMesh) {
      await this.toggleMeshByName(this.assetAdjustmentsService.selectedMesh.name);
    }
    this.assetAdjustmentsService.saveAll();
  }

  public saveMeshLastName(): void {
    this.meshLastName = this.assetAdjustmentsService.selectedMesh.name;
  }

  public async setMeshName(): Promise<void> {
    const mesh: MeshData = this.utils.deepCopyByValue(this.assetAdjustmentsService.selectedMesh);
    mesh.name = this.meshLastName;
    this.meshLastName = this.assetAdjustmentsService.selectedMesh.name;
    await this.assetAdjustmentsService.selecteMeshByName(null);
    this.assetAdjustmentsService.setMeshProps(mesh, 'name', this.meshLastName, true);
    await this.assetAdjustmentsService.getMeshesData();
    this.toggleMeshByName(this.meshLastName);
  }

  public selectMeshName(event: MatSelectChange): void {
    this.saveMeshLastName();
    this.assetAdjustmentsService.selectedMesh.name = event.value;
    this.setMeshName();
  }

  public getMapAlias(name: string): string {
    if (AssetAdjustmentsComponent.MAPS_DICTIONARY[name] && AssetAdjustmentsComponent.MAPS_DICTIONARY[name].alias) {
      return AssetAdjustmentsComponent.MAPS_DICTIONARY[name].alias;
    }
    return name;
  }

  public toggleAllAnimations(state: boolean): void {
    for (const i in this.assetAdjustmentsService.meshAnimations) {
      if (Reflect.has(this.assetAdjustmentsService.meshAnimations, i)) {
        this.assetAdjustmentsService.meshAnimations[i].active = state;
      }
    }
    this.assetAdjustmentsService.updateMeshAnimations();
  }

  public changeLightNickname(light: AssetAdjustments): void {
    light.options.nickname = light.nickname;
    this.assetAdjustmentsService.changeValue(light);
  }

  public async addAnnotation(): Promise<void> {
    const pos = await this.assetAdjustmentsService.broadcastPosition();
    const annotation = {
      annotationId: new Date().getTime(),
      cameraPositions: [pos],
      meshAnimations: this.assetAdjustmentsService.hasMeshAnimations ? this.utils.deepCopyByValue(this.assetAdjustmentsService.meshAnimations) : {},
      position: { x: this.assetAdjustmentsService.boundingBox.x, y: this.assetAdjustmentsService.boundingBox.y, z: this.assetAdjustmentsService.boundingBox.z },
      annotationType: AnnotationType.HOT_SPOT,
      color: '#070707',
      textDuration: 5,
      editMode: true
    } as Annotation;

    for (const i in annotation.meshAnimations) {
      if (Reflect.has(annotation.meshAnimations, i)) {
        annotation.meshAnimations[i].active = false;
        annotation.meshAnimations[i].once = false;
      }
    }
    this.assetAdjustmentsService.annotations.push(annotation);
    this.updateAnnotations();
  }

  public async setCurrentAnnotationPos(annotation: Annotation): Promise<void> {
    annotation.cameraPositions = [await this.assetAdjustmentsService.broadcastPosition()];
    this.updateAnnotations();
  }

  public onAddAnnotationImg(fileList: Array<FileObject>, annotation: Annotation): void {
    if (fileList.length > 0) {
      fileList.forEach(file => {
        const imgSrc = file.base64;
        const img = new Image();
        img.src = imgSrc;
        img.onload = () => {
            annotation.imageProportion = img.width / img.height;
            annotation.img = file.base64;
            this.updateAnnotations();
        }
      });
    }
  }

  public updateAnnotations(): void {
    this.assetAdjustmentsService.updateAnnotations();
  }

  public removeAnnotations(annotation: Annotation): void {
    for (let i = 0; i < this.assetAdjustmentsService.annotations.length; i++) {
      if (annotation.annotationId === this.assetAdjustmentsService.annotations[i].annotationId) {
        this.assetAdjustmentsService.annotations.splice(i, 1);
        break;
      }
    }
    this.updateAnnotations();
  }

  public setCurrentScreenshot(screenshot: ArtistsJobsResourcesAlignment): void {
    this.onAlignment.next(screenshot);
  }

  public async setTextureToMesh(obj): Promise<void> {
    if (obj) {
      const name = this.assetAdjustmentsService.selectedMesh.name;
      delete this.meshLastName;
      delete this.assetAdjustmentsService.selectedMesh;
      await this.assetAdjustmentsService.selecteMeshByName(null);
      setTimeout(() => {
        this.assetAdjustmentsService.materializeMesh(name, obj.value);
        delete this.assetAdjustmentsService.materials;
      });
    }
  }

  onFurFileChange(fileList: Array<FileObject>) {
    if (fileList.length > 0) {
      this.assetAdjustmentsService.meshFurOptions.diffuseTexture = fileList[0].base64;
      this.assetAdjustmentsService.onMeshFurOptions();
    }
  }

  onDiamondFileChange(fileList: Array<FileObject>) {
    if (fileList.length > 0) {
      this.assetAdjustmentsService.meshDiamondOptions.envMap = fileList[0].base64;
      this.assetAdjustmentsService.onMeshDiamondOptions();
    }
  }

  ngOnDestroy() {
    this.assetAdjustmentsService.onDestroy();
    this.assetAdjustmentsHelperService.onDestroy();
    this.subs.forEach(s => s.unsubscribe());
    this.afterLightsUpdate = [];
    this.assetAdjustmentsHelperService.noDamping = null;
  }
}
