<div class="wrap">
  <div fxLayout="col" fxLayoutAlign="space-around center" fxLayoutGap="2">
    <app-retailers-autocomplete [placeholder]="'search for retailers'" class="disp-block" [clear-on-select]="true"
      (retailer-change)="onRetailerChange($event)"></app-retailers-autocomplete>
    <div>or</div>
    <app-users-autocomplete [placeholder]="'CMS User'" (user-change)="onUserChanged($event)" [show-all]="true">
    </app-users-autocomplete>
  </div>
  <div class="users scrollbar">
    <div fxLayout="row" fxLayoutAlign="space-around center" class="flex-wrap-wrap" fxLayoutGap="2">
      <app-user-card [use-dialog]="true" *ngFor="let user of users" fxFlex="31" [user]="user"></app-user-card>
    </div>
  </div>
</div>