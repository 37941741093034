<div class="preview-files">
  <div class="disp-flex space-between align-center header-wrapper">
    <span class="preview-files__title">Preview files</span>
    <mat-icon mat-dialog-close class="pointer">close</mat-icon>
  </div>

  <div class="preview-container">
    <ng-container [ngSwitch]="currentResource.resourceType">
      <img *ngSwitchCase="resourceType.IMAGE" [src]="currentResource.src">
      <video *ngSwitchCase="resourceType.VIDEO" [src]="currentResource.src" autoplay muted loop playsinline crossorigin="anonymous" type="video/mp4">
        <source [src]="currentResource.src" type="video/mp4">
      </video>
      <ng-container *ngSwitchCase="resourceType.MODEL">
        <app-asset-communication *ngIf="currentResource.src; else loading" class="" [model-url]="currentResource.src"
        ></app-asset-communication>
        <ng-template #loading>
          <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
        </ng-template>
      </ng-container>
    </ng-container>
  </div>

  <div class="slider-container scrollbar" appMouseDragScroll>
    <div *ngFor="let item of resources" class="slider-item" [ngClass]="{'slider-item--selected': item === currentResource}"
    (click)="onResourceSelect(item)">
      <ng-container [ngSwitch]="item.resourceType">
        <img *ngSwitchCase="resourceType.IMAGE" [src]="item.src">
        <img *ngSwitchCase="resourceType.MODEL" [src]="modelIcon(item)">
        <img *ngSwitchCase="resourceType.VIDEO" [src]="videoIcon">
        <mat-spinner diameter="56" strokeWidth="4" *ngIf="item.loading"></mat-spinner>
      </ng-container>
    </div>
  </div>
</div>
