<div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="4" class="flex-wrap-wrap">
    <div fxFlex>
        <mat-form-field>
            <mat-label>tipo de interfaz</mat-label>
            <mat-select [(ngModel)]="uiType">
                @for (ui of uiTypes; track $index) {
                <mat-option [value]="ui.key">{{ui.value}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
    </div>

    <div fxFlex>
        <mat-form-field>
            <mat-label>marcas</mat-label>
            <mat-select [(ngModel)]="brand" [disabled]="uiType !== PC_COMPONENTES_UI_TYPE.BRANDED">
                @for (b of brands; track $index) {
                <mat-option [value]="b.key">{{b.value}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
    </div>

    <div fxFlex>
        <mat-form-field>
            <mat-label>tema</mat-label>
            <mat-select [(ngModel)]="theme" [disabled]="uiType !== PC_COMPONENTES_UI_TYPE.BRANDED">
                @for (t of themes; track $index) {
                <mat-option [value]="t.key">{{t.value}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
    </div>

    <div fxFlex>
        <button mat-raised-button (click)="copyLink()" color="primary">
            copiar al portapapeles
        </button>
    </div>
</div>