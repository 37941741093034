import { Component, Input } from '@angular/core';
import { ProductResource } from '../product';
import { UtilsService } from 'src/app/shared/utils.service';
import { KeyValuePair } from 'src/app/shared/enums';
import { BroadcasterNotificationType } from 'src/app/communication/broadcaster-notifications';
import { environment } from 'src/environments/environment';

enum PC_COMPONENTES_UI_TYPE {
  LEGACY = 1,
  NEW = 2,
  BRANDED = 3,
}

enum PC_COMPONENTES_BRAND {
  'PC Com Essential' = 1,
  'Owol Tech' = 2,
  'Original' = 3,
  'Nilait' = 4,
  'Pc Com' = 5,
  'Tempest Gaming' = 6,
  'Forgeon' = 7
}

enum PC_COMPONENTES_THEME {
  LIGHT = 1,
  DARK = 2,
}

@Component({
  selector: 'app-pc-componentes-generator',
  templateUrl: './pc-componentes-generator.component.html',
  styleUrl: './pc-componentes-generator.component.scss',
  standalone: false
})
export class PcComponentesGeneratorComponent {
  @Input() resource: ProductResource;
  public uiTypes: Array<KeyValuePair>;
  public brands: Array<KeyValuePair>;
  public themes: Array<KeyValuePair>;
  public uiType: PC_COMPONENTES_UI_TYPE;
  public brand: PC_COMPONENTES_BRAND;
  public theme: PC_COMPONENTES_THEME;
  public PC_COMPONENTES_UI_TYPE = PC_COMPONENTES_UI_TYPE;
  constructor(private utils: UtilsService) {
    this.uiTypes = [
      {
        key: PC_COMPONENTES_UI_TYPE.LEGACY,
        value: PC_COMPONENTES_UI_TYPE[PC_COMPONENTES_UI_TYPE.LEGACY]
      },
      {
        key: PC_COMPONENTES_UI_TYPE.NEW,
        value: PC_COMPONENTES_UI_TYPE[PC_COMPONENTES_UI_TYPE.NEW]
      },
      {
        key: PC_COMPONENTES_UI_TYPE.BRANDED,
        value: PC_COMPONENTES_UI_TYPE[PC_COMPONENTES_UI_TYPE.BRANDED]
      }
    ];

    this.brands = [
      {
        key: PC_COMPONENTES_BRAND['Forgeon'],
        value: PC_COMPONENTES_BRAND[PC_COMPONENTES_BRAND['Forgeon']]
      },
      {
        key: PC_COMPONENTES_BRAND['Nilait'],
        value: PC_COMPONENTES_BRAND[PC_COMPONENTES_BRAND['Nilait']]
      },
      {
        key: PC_COMPONENTES_BRAND['Original'],
        value: PC_COMPONENTES_BRAND[PC_COMPONENTES_BRAND['Original']]
      },
      {
        key: PC_COMPONENTES_BRAND['Owol Tech'],
        value: PC_COMPONENTES_BRAND[PC_COMPONENTES_BRAND['Owol Tech']]
      },
      {
        key: PC_COMPONENTES_BRAND['PC Com Essential'],
        value: PC_COMPONENTES_BRAND[PC_COMPONENTES_BRAND['PC Com Essential']]
      },
      {
        key: PC_COMPONENTES_BRAND['Pc Com'],
        value: PC_COMPONENTES_BRAND[PC_COMPONENTES_BRAND['Pc Com']]
      },
      {
        key: PC_COMPONENTES_BRAND['Tempest Gaming'],
        value: PC_COMPONENTES_BRAND[PC_COMPONENTES_BRAND['Tempest Gaming']]
      }
    ];

    this.themes = [
      {
        key: PC_COMPONENTES_THEME.LIGHT,
        value: PC_COMPONENTES_THEME[PC_COMPONENTES_THEME.LIGHT]
      },
      {
        key: PC_COMPONENTES_THEME.DARK,
        value: PC_COMPONENTES_THEME[PC_COMPONENTES_THEME.DARK]
      }
    ];
  }

  private getPrefixByUiType(t: PC_COMPONENTES_UI_TYPE) {
    switch (t) {
      case PC_COMPONENTES_UI_TYPE.LEGACY: {
        return 'https://v.hexa3d.io/pccomponentes.html';
      }
      case PC_COMPONENTES_UI_TYPE.NEW: {
        return 'https://v.hexa3d.io/pccomponentes2024.html';
      }
      case PC_COMPONENTES_UI_TYPE.BRANDED: {
        return 'https://v.hexa3d.io/pccomponentes2025.html';
      }
    }
  }

  getLink() {
    let isValid = true;
    if (!this.uiType)
      isValid = false;
    else if (this.uiType === PC_COMPONENTES_UI_TYPE.BRANDED && (!this.brand || !this.theme))
      isValid = false;
    if (isValid) {
      let link = this.getPrefixByUiType(this.uiType);
      if (this.brand) {
        link = this.utils.setUrlParam(link, 'brand', this.brand.toString())
        if (this.theme) {
          link = this.utils.setUrlParam(link, 'theme', this.theme.toString())
        }
        link = this.utils.setUrlParam(link, 'api', `https://rc.hexa3d.io/product?rid=${environment.pcRID}&pid=${this.resource.product_id}&resource_type=2`);
      }
      else
        link = this.utils.setUrlParam(link, 'api', `${encodeURIComponent(`https://rc.hexa3d.io/product?rid=${environment.pcRID}&pid=${this.resource.product_id}&resource_type=2`)}`);
      return link;
    }
    else
      this.utils.notifyUser({ type: BroadcasterNotificationType.Error, text: 'Faltan campos obligatorios' });
  }

  copyLink() {
    const l = this.getLink();
    if (l)
      this.utils.copyClipboard(l, true);
  }

}
